import type { Notification } from '~/store/notifications';

type PushNotificationParam = Omit<Notification, 'id'> & { id?: number };

/**
 * Composable to use the notification component from every component.
 * See {@link Notification} for more info about the data to pass to create a new item.
 *
 * ```typescript
 * import useNotifications from '~/composables/useNotifications';
 *
 * // setup method or <script setup>
 * const { pushNotification, removeNotification } = useNotifications();
 *
 * // create a new notification
 * pushNotification({
 *   id: 1,
 *   title: 'Success!'
 *   message: 'Action went well!';
 *   type: 'success';
 * });
 *
 * // remove from notification stack
 * removeNotification({
 *   id: 1,
 *   title: 'Success!'
 *   message: 'Action went well!';
 *   type: 'success';
 * });
 *
 * // or via id
 * removeNotification(1);
 * ```
 */
export default () => {
  const notificationStore = useNotificationsStore();

  /**
   * List of notifications sorted by the newest first
   */
  const notifications = computed<Array<Notification>>(
    () => notificationStore.sortedNotifications,
  );

  /**
   * Create a new notificaiton and pass it into the notification stack.
   *
   * @param {Notification} notification - the {@link PushNotificationParam} object. If an id is not provided, the UNIX client timestamp will be set as id instead.
   */
  const pushNotification = (notification: PushNotificationParam) => {
    let id = notification.id;
    if (id == null) {
      id = new Date().getTime();
    }
    notificationStore.pushNotification({ ...notification, id });
  };

  /**
   * Shorthand for pushing a success notification without entering the 'success' type
   *
   * @param {Notification} notification - the {@link PushNotificationParam} object. If an id is not provided, the UNIX client timestamp will be set as id instead.
   */
  const pushSuccessNotification = (
    notification: Omit<PushNotificationParam, 'type'>,
  ) => {
    pushNotification({
      ...notification,
      type: 'success',
    });
  };

  /**
   * Shorthand for pushing a error notification without entering the 'error' type
   *
   * @param {Notification} notification - the {@link PushNotificationParam} object. If an id is not provided, the UNIX client timestamp will be set as id instead.
   */
  const pushErrorNotification = (
    notification: Omit<PushNotificationParam, 'type'>,
  ) => {
    pushNotification({
      ...notification,
      type: 'error',
    });
  };

  /**
   * Remove a specific notification.
   *
   * @param {Notification} notification - a {@link Notification} object or an id
   */
  const removeNotification = (notification: Notification | number) => {
    notificationStore.removeNotification(notification);
  };

  return {
    notifications,
    pushNotification,
    pushSuccessNotification,
    pushErrorNotification,
    removeNotification,
  };
};
