import type { Locale } from 'vue-i18n';

const localeOverrideMapping: Record<Locale, Record<string, string>> = {
  de: {
    EUR: 'de',
    USD: 'us/en',
    JPY: 'ja',
    GBP: 'gb',
    AUD: 'us/en',
  },
  en: {
    EUR: 'en',
    USD: 'us/en',
    JPY: 'ja',
    GBP: 'gb',
    AUD: 'us/en',
  },
  fr: {
    EUR: 'fr',
    USD: 'us/en',
    JPY: 'ja',
    GBP: 'gb',
    AUD: 'us/en',
  },
  it: {
    EUR: 'it',
    USD: 'us/en',
    JPY: 'ja',
    GBP: 'gb',
    AUD: 'us/en',
  },
  pl: {
    EUR: 'pl',
    USD: 'us/en',
    JPY: 'ja',
    GBP: 'gb',
    AUD: 'us/en',
  },
  es: {
    EUR: 'es',
    USD: 'us/en',
    JPY: 'ja',
    GBP: 'gb',
    AUD: 'us/en',
  },
  pt: {
    EUR: 'pt',
    USD: 'us/en',
    JPY: 'ja',
    GBP: 'gb',
    AUD: 'us/en',
  },
  nl: {
    EUR: 'nl',
    USD: 'us/en',
    JPY: 'ja',
    GBP: 'gb',
    AUD: 'us/en',
  },
  ru: {
    EUR: 'en',
    USD: 'us/en',
    JPY: 'ja',
    GBP: 'gb',
    AUD: 'us/en',
  },
  ja: {
    EUR: 'en',
    USD: 'us/en',
    JPY: 'ja',
    GBP: 'gb',
    AUD: 'us/en',
  },
  zh: {
    EUR: 'en',
    USD: 'zh',
    JPY: 'ja',
    GBP: 'gb',
    AUD: 'us/en',
  },
};

export function getLegacyLocalePrefix(
  locale: Locale,
  currency: string,
): string {
  if (
    locale in localeOverrideMapping &&
    currency in localeOverrideMapping[locale]
  ) {
    return localeOverrideMapping[locale][currency];
  }
  return 'en';
}

export function getRegion(locale: Locale): string {
  switch (locale) {
    case 'en':
      return 'int';
    default:
      return 'eur';
  }
}
